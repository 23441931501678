header {
    color: #a8a8a8;
    font-weight: 400;
}

header h1 {
    font-family: 'Sacramento', cursive;
    color: #a3a3a3;
    font-weight: 400;
}

header .nav-link {
    padding: 0;
}

/* Logo */

header .header-logo {
    width: 200px;
}

/* Navbar */
/* header .navbar {
    padding: 0;
} */

/* All nav links */
header .navbar .nav-link,
header .navbar a {
    text-decoration: none;
    color: #a8a8a8;
    transition: 300ms;
    padding: 1.75em 0;
}

header .navbar a:hover,
header .navbar a:focus,
header .navbar a.active {
    border-top: 3px solid #111111;
    border-left: none;
    border-right: none;
    border-bottom: none;
    color: #111111;
}

/* Navbar Brand */
header .navbar .navbar-brand:hover,
header .navbar .navbar-brand:focus,
header .navbar .navbar-brand:active {
    border: none;
    color: initial;
    padding: 0;
}

/* Offcanvas */

.offcanvas h1 {
    font-family: 'Sacramento', cursive;
    color: #a3a3a3;
    font-weight: 400;
}

.offcanvas .offcanvas-body #header-nav .nav-link,
.offcanvas .offcanvas-body #header-nav a {
    text-decoration: none;
    transition: 300ms;
    padding: 1em 0;
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #717d8a;
    text-align: left;
}

.offcanvas .offcanvas-body #header-nav a:hover,
.offcanvas .offcanvas-body #header-nav a:focus,
.offcanvas .offcanvas-body #header-nav a:active {
    color: #292929;
}

.offcanvas .offcanvas-body #header-nav > .nav-link {
    color: #292929;
}

header .navbar-toggler {
    border: none;
}

.navbar-toggler:focus {
    box-shadow: none;
}

header .navbar-toggler-icon {
    background-image: url(../assets/images/toggler.svg);
    width: 24px;
    height: 24px;
}

.btn-close {
    background-image: url(../assets/images/close.svg);
    background-repeat: no-repeat;
    background-size: 0.8em;
    background-position: center;
    padding: 1em;
}

.contact-btn {
    padding: 12px 0 !important;
    border-radius: 20px;
    border: 1px solid #111111;
    background-color: #111111;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.05px;
}

.contact-btn:hover,
.contact-btn:focus,
.contact-btn:active {
    border: 1px solid #a3a3a3;
    background-color: #a3a3a3;
}

@media (max-width: 991px) {
    .offcanvas .offcanvas-body #header-nav .nav-link,
    .offcanvas .offcanvas-body #header-nav a {
        padding: 1em 0;
    }
}

@media (max-width: 767px) {
    .overlay-menu {
        position: absolute;
        width: 100%; /* Set the desired width */
        left: 0;
        background-color: rgb(255, 255, 255);
        transition: width 0.3s ease-in-out;
        z-index: 1000; /* Ensure the Navbar is above the content */
    }

    /* .overlay-menu.expanded {
        width: 100%;
    } */

    .overlay-menu.expanded .navbar-collapse {
        background-color: rgb(255, 255, 255);
        /* height: 100vh; */
        z-index: 1000;
        overflow-y: scroll;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    header .header-logo,
    .offcanvas .header-logo {
        width: 110px;
    }

    header .navbar .nav-link,
    header .navbar a {
        font-size: 24px;
        font-weight: 500;
        padding-top: 0;
        padding-bottom: 0.5em;
    }

    header .navbar a:hover,
    header .navbar a:focus,
    header .navbar a.active {
        border-bottom: 3px solid #111111;

        border-top: none;
    }

    .navbar-collapse {
        padding: 1em 0;
    }

    header #header-nav .show > .nav-link {
        border: none;
    }

    header .navbar .dropdown-menu {
        margin: 18px;
    }

    .contact-btn:hover,
    .contact-btn:focus,
    .contact-btn:active {
        border: none !important;
    }
}

@media (max-width: 575px) {
    header {
        padding: 1.05rem 0;
    }
    /* Logo */
    header .header-logo,
    .offcanvas .header-logo {
        width: 110px;
    }

    header .navbar .nav-link,
    header .navbar a {
        font-size: 7vw;
    }
}

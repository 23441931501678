/* Home Hero */
#home .home-hero {
    padding-top: 8em;
}

#home .home-hero h1 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    line-height: 120%;
    color: #606060;
    margin-bottom: 32px;
}

#home .home-hero .subtitle {
    font-size: 22px;
    line-height: 30px;
    text-transform: uppercase;
    color: #a3a3a3;
    font-weight: 700;
    margin-bottom: 32px;
    letter-spacing: 2.09px;
}

#home .home-hero button {
    font-family: 'Inter', sans-serif;
    border: 1px solid #a3a3a3;
    background: #a3a3a3;
    width: 25%;
    border-radius: 3.125rem;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5rem;
    margin-bottom: 81px;
}

#home .home-hero button:hover,
#home .home-hero button:focus,
#home .home-hero button:active {
    background-color: transparent;
    border: 1px solid #606060;
    color: #606060;
}

@media (max-width: 74.9375rem) {
    #home .home-hero button {
        width: 30%;
    }
}

@media (max-width: 61.9375rem) {
    #home .home-hero button {
        width: 40%;
    }
}

@media (max-width: 767px) {
    #home .home-hero {
        padding-top: 5em;
    }

    #home .home-hero button {
        width: 50%;
    }
}

@media (max-width: 575px) {
    #home .home-hero {
        padding-top: 4em;
    }

    #home .home-hero button {
        width: 70%;
        margin-bottom: 3em;
    }
    #home .home-hero .subtitle {
        font-size: 16px;
        margin-bottom: 20px;
    }
    #home .home-hero h1 {
        margin-bottom: 20px;
    }
}

/* Home About */
#home .home-about {
    padding-top: 5em;
    padding-bottom: 5em;
}

/* Mission */
#home .home-about #mission .grey {
    width: 1.375rem;
    height: 0.4375rem;
    background-color: #a3a3a3;
}

#home .home-about #mission h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 1;
    color: #0a0a33;
    margin-bottom: 41px;
}

#home .home-about #mission img {
    margin-top: 50px;
}

#home .home-about #mission p {
    color: #444549;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
}

@media (max-width: 1199px) {
    #home .home-about #mission p {
        font-size: 1rem;
    }
}

#home .home-about #mission .circle-div {
    position: relative;
    padding-top: 12em;
    /* padding-bottom: 5em; */
}

#home .home-about #mission .circle {
    border: 2px solid #a3a3a3;
    border-radius: 100%;
    width: 500px;
    height: 500px;
    padding: 3em;
}

#home .home-about #mission .circle-bottom {
    position: relative;
    bottom: 6rem;
}

#home .home-about #mission .circle-div h4 {
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: #0a0a33;
    letter-spacing: 2px;
    text-transform: uppercase;
}

#home .home-about #mission .circle-div p {
    margin-bottom: 0;
    font-weight: 300;
    font-size: 1rem;
    line-height: 27px;
    color: #606060;
}

@media (max-width: 1199px) {
    /* #home .home-about #mission {
        padding-bottom: 13em;
    } */
    #home .home-about #mission .circle {
        width: 450px;
        height: 450px;
    }

    #home .home-about #mission .circle-bottom {
        bottom: 4.5rem;
    }

    #home .home-about #mission .circle-div h4 {
        font-size: 1.4rem;
    }

    #home .home-about #mission .circle-div p {
        font-size: 14px;
    }

    #home .home-about #mission .circle-left {
        position: relative;
        left: 12px;
    }

    #home .home-about #mission .circle-right {
        position: relative;
        right: 12px;
    }
}

@media (max-width: 1050px) {
    #home .home-about {
        padding-bottom: 4em;
    }
}

@media (max-width: 991px) {
    #home .home-about #mission img {
        margin-top: 30px;
    }

    #home .home-about #mission .circle {
        width: 80%;
        height: 500px;
    }
    #home .home-about #mission .circle-bottom {
        bottom: 4rem;
    }

    #home .home-about #mission .circle-bottom .circle-left {
        left: 0;
    }

    #home .home-about #mission .circle-bottom .circle-right {
        right: 0;
        bottom: 4rem;
    }

    #home .home-about #mission .circle-div {
        padding-top: 10em;
        padding-bottom: 3em;
    }

    #home .home-about {
        padding-bottom: 3em;
    }
}

@media (max-width: 767px) {
    #home .home-about #mission .circle {
        width: 100%;
        height: 500px;
    }
    #home .home-about #mission .circle-bottom,
    #home .home-about #mission .circle-bottom .circle-right {
        bottom: 2.5rem;
    }

    #home .home-about #mission .circle-div {
        padding-top: 7em;
        padding-bottom: 2em;
    }

    #home .home-about {
        padding-bottom: 2em;
    }
}
@media (max-width: 575px) {
    #home .home-about {
        padding-top: 5em;
    }
}

@media (max-width: 500px) {
    /* #home .home-about #mission .circle {
        width: 100%;
        height: 350px;
    } */

    #home .home-about #mission .circle-bottom,
    #home .home-about #mission .circle-bottom .circle-right {
        bottom: 2rem;
    }

    #home .home-about #mission .circle-div h4 {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
    }

    #home .home-about #mission .circle-div p {
        font-weight: 300;
        font-size: 11px;
        line-height: 16px;
    }

    #home .home-about #mission .circle {
        height: 400px;
    }
}

@media (max-width: 425px) {
    #home .home-about #mission h2 {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 32px;
    }

    #home .home-about #mission p {
        font-size: 15px;
        line-height: 22px;
    }
}

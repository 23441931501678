#getInTouch {
    background: rgba(17, 17, 17, 0.0667);
    padding: 6em 0;
}

#getInTouch h1 {
    color: #111111;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
}

#getInTouch p {
    color: #606060;
    font-size: 1.25rem;
    line-height: 30px;
}

#getInTouch .contact-us,
#getInTouch .detail {
    width: 50%;
}

#getInTouch button a {
    width: 45%;
}

#getInTouch button {
    font-family: 'Inter', sans-serif;
    background-color: transparent;
    border: 1px solid #606060;
    border-radius: 50px;
    color: #606060;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
}

#getInTouch .arrow {
    font-size: 21px;
    color: #606060;
}

#getInTouch button:hover,
#getInTouch button:focus,
#getInTouch button:active {
    background-color: #606060;
    border: 1px solid #606060;
    color: #fff;
}

#getInTouch button:hover .arrow,
#getInTouch button:focus .arrow,
#getInTouch button:active .arrow {
    color: #fff;
}

@media (max-width: 1200px) {
    #getInTouch a {
        width: 55%;
    }
}

@media (max-width: 992px) {
    #getInTouch .contact-us {
        width: 45%;
    }

    #getInTouch .detail {
        width: 55%;
    }

    #getInTouch a {
        width: 70%;
    }
}

@media (max-width: 767px) {
    #getInTouch .contact-us,
    #getInTouch .detail {
        width: 100%;
    }

    #getInTouch {
        padding: 5em 0;
    }

    #getInTouch {
        text-align: center;
    }

    #getInTouch button {
        margin-top: 1em;
        width: 70%;
    }
}

@media (max-width: 600px) {
    #getInTouch button {
        width: 90%;
    }

    #getInTouch h1 {
        font-size: 2.8125rem;
        line-height: 45px;
    }
}

@media (max-width: 575px) {
    #getInTouch {
        text-align: left;
        padding: 4rem 0;
    }
    #getInTouch button {
        width: 80%;
    }
}

@media (max-width: 425px) {
    #getInTouch {
        padding: 2rem 0;
    }
    #getInTouch button {
        width: 100%;
    }

    #getInTouch h1 {
        font-size: 20px;
        line-height: 30px;
    }

    #getInTouch p {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #444549;
    }
}

#projects {
    padding-bottom: 3em;
}

#projects .banner1,
#projects .banner2 {
    padding: 9em 0;
    background: #aaa url(../../assets/images/projects-img/project-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #ffffff;
}

#projects .banner2 {
    padding: 10em 0;
    background: #aaa url(../../assets/images/projects-img/event-banner.png);
}

#projects h1 {
    font-weight: 600;
    font-family: 'Raleway', sans-serif;
}

#projects article {
    padding: 5em 0;
}

#projects .details {
    width: 48%;
}

#projects .img-div {
    width: 45%;
}

@media (max-width: 991px) {
    #projects .details,
    #projects .img-div {
        width: 100%;
    }

    #projects .img-div {
        width: 100%;
    }

    #projects .img-div img {
        width: 100%;
    }
}

@media (max-width: 575px) {
    #projects #youtube,
    #projects #tedx,
    #projects #lbs {
        padding-bottom: 0em;
    }
}

#projects .grey {
    width: 1.375rem;
    height: 0.4375rem;
    background-color: #a3a3a3;
}

#projects h2 {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 1;
    color: #292929;
    margin-bottom: 41px;
}

#projects p {
    color: #606060;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
}

@media (max-width: 1199px) {
    #projects p {
        font-size: 1rem;
    }
}

@media (max-width: 575px) {
    #projects .banner1,
    #projects .banner2 {
        padding: 6em 0;
        background-position: top;
    }

    #projects h1 {
        letter-spacing: -0.02em;
    }
}

@media (max-width: 425px) {
    #projects h2 {
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 32px;
    }

    #projects p {
        font-size: 15px;
        line-height: 22px;
    }
}

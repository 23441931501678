#home #home-cases #numbers {
    padding-top: 110px;
}

#home #home-cases #numbers .datas {
    width: 65%;
}

#home #home-cases #numbers h2 {
    width: 25%;
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 50px;
    line-height: 100%;
    color: #0a0a33;
}

#home #home-cases #numbers .data {
    width: 30%;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    padding: 4em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#home #home-cases #numbers .data h3 {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 41px;
    line-height: 62px;
    color: #111;
    margin-bottom: 0;
}

#home #home-cases #numbers .data p {
    margin-top: 0.5em;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    color: #111;
}

#home #home-cases #numbers .data .box {
    border: 1px solid #fff;
    width: 50px;
}

#home #home-cases #numbers .data1 {
    background-image: url(../../assets/images/home-img/about-stroke-1.svg);
}

#home #home-cases #numbers .data2 {
    background-image: url(../../assets/images/home-img/about-stroke-2.svg);
    margin-bottom: 12em;
}

#home #home-cases #numbers .data3 {
    background-image: url(../../assets/images/home-img/about-stroke-3.svg);
}

@media (max-width: 1399px) {
    #home #home-cases #numbers h2 {
        width: 28%;
    }

    #home #home-cases #numbers .data {
        width: 32%;
    }
}

@media (max-width: 1199px) {
    #home #home-cases #numbers .datas,
    #home #home-cases #numbers h2 {
        width: 100%;
    }

    #home #home-cases #numbers .data {
        width: 30%;
    }
}

@media (max-width: 991px) {
    #home #home-cases #numbers .data {
        width: 33%;
        background-size: contain;
    }
}

@media (max-width: 767px) {
    #home #home-cases #numbers .data h3 {
        font-size: 30px;
        line-height: 42px;
    }

    #home #home-cases #numbers .data p {
        margin-top: 0;
    }
}

@media (max-width: 575px) {
    #home #home-cases #numbers .data h3 {
        font-size: 24px;
    }

    #home #home-cases #numbers .data p {
        font-size: 16px;
    }
}

@media (max-width: 425px) {
    #home #home-cases #numbers .data h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #0a0a33;
    }

    #home #home-cases #numbers .data p {
        margin-top: 0.5em;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
    }

    #home #home-cases #numbers h2 {
        font-weight: 600;
        font-size: 32px;
        color: #101828;
    }
}

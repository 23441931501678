#contact {
    padding-top: 7em;
    padding-bottom: 7em;
}

#contact h2 {
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    color: #606060;
}

#contact p {
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: #606060;
}

#contact textarea {
    height: 140px;
}

#contact label {
    font-size: 1rem;
    color: #606060;
    font-weight: 500;
}

#contact input,
#contact textarea {
    border-radius: 10px;
    background-color: #f0f0f0;
    border: none;
}

#contact .form-control.is-valid,
#contact .was-validated .form-control:valid {
    background-image: none;
}

#contact button {
    padding: 0 32px;
    font-size: 18px;
    font-weight: 400;
    background: #606060;
    border: none;
}

#contact .btn-2 {
    background: #fff;
    border: 1px solid #606060;
    color: #606060;
}

#contact .contact-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #292929;
    -webkit-animation: text-focus-in 4s cubic-bezier(0.55, 0.085, 0.68, 0.53)
        both;
    animation: text-focus-in 4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes text-focus-in {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}
@keyframes text-focus-in {
    0% {
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

@media (max-width: 767px) {
    #contact .contact-text {
        font-weight: 400;
        font-size: 14px;
    }
}

@media (max-width: 575px) {
    #contact {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
}

@media (max-width: 425px) {
    #contact h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 44px;
    }

    #contact p {
        font-size: 18px;
        line-height: 27px;
    }

    #contact input,
    #contact textarea {
        border-radius: 8px;
        font-weight: 400;
    }

    #contact button {
        font-size: 16px;
        font-weight: 500;
        background: transparent;
        border: 1px solid #606060;
        color: #606060;
        width: 100%;
    }

    #contact .btn-2 {
        background-color: #606060;
        color: #fff;
    }
}

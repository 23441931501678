#home .home-about #clients {
    padding-top: 6em;
    padding-bottom: 3em;
}

#home .home-about #clients .business {
    font-size: 16px;
    line-height: 1.3125rem;
    color: #505055;
}

#home .home-about #clients .comp-logos img {
    max-width: 100%;
    filter: grayscale(100%);
    transition: 500ms;
}

#home .home-about #clients .comp-logos img:hover {
    filter: none;
    transform: scale(1.2);
}

@media (max-width: 992px) {
    #home .home-about #clients {
        padding-top: 5em;
    }
}

@media (max-width: 767px) {
    #home .home-about #clients {
        padding-top: 4em;
    }
}

@media (max-width: 575px) {
    #home .home-about #clients {
        padding-top: 3em;
    }
}

@media (max-width: 425px) {
    #home .home-about #clients .business {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.01em;
    }
}

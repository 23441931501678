/* Footer Top */
footer h1 {
    font-family: 'Sacramento', cursive;
    color: #a3a3a3;
    font-weight: 400;
}

footer #footer-top {
    padding-top: 5em;
    padding-bottom: 3em;
}

footer #footer-top h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5rem;
    color: #a3a3a3;
}

footer #footer-top .left {
    width: 18%;
}

footer #footer-top .left .address {
    font-size: 14px;
    line-height: 1.3125rem;
    color: #606060;
}

footer #footer-top .center {
    width: 40%;
}

footer #footer-top .center a {
    text-decoration: none;
    font-size: 14px;
    line-height: 1.3125rem;
    color: #606060;
    transition: 300ms;
}

footer #footer-top .center a:hover,
footer #footer-top .center a:focus,
footer #footer-top .center a:active {
    font-weight: 500;
    color: #111111;
}

footer #footer-top .center .icon {
    font-size: 20px;
}

@media (max-width: 1200px) {
    footer #footer-top .center,
    footer #footer-top .left {
        width: 48%;
    }
}

@media (max-width: 992px) {
    footer #footer-top .center {
        width: 68%;
    }

    footer #footer-top .left {
        width: 30%;
    }

    footer h1 {
        font-size: 34px !important;
    }
}

@media (max-width: 767px) {
    footer #footer-top h5 {
        font-weight: 600;
    }
    footer #footer-top .left .address {
        font-size: 15px;
        line-height: 1.3125rem;
        color: #667085;
    }
    footer #footer-top {
        padding-top: 3em;
    }

    footer #footer-top .left {
        width: 100%;
    }

    footer #footer-top .center {
        width: 100%;
        margin-top: 2em;
    }

    footer .center .company,
    footer .center .legal,
    footer .center .contact {
        width: 50%;
    }

    footer #footer-top .center a {
        font-size: 15px;
        line-height: 24px;
        color: #667085;
    }

    footer #footer-top .icon {
        display: none;
    }

    footer #footer-bottom .copyright {
        font-size: 15px;
        line-height: 24px;

        color: #98a2b3;
    }
}

/* Footer Bottom */
footer #footer-bottom {
    padding: 2em 0;
    border-top: 1px solid #bbbbbb;
}

footer #footer-bottom .copyright {
    color: #606060;
    font-size: 0.875rem;
}

footer #footer-bottom .social-icons a {
    color: #a3a3a3;
    text-decoration: none;
    font-size: 20px;
    transition: 500ms;
}

footer #footer-bottom .social-icons a:hover,
footer #footer-bottom .social-icons a:focus,
footer #footer-bottom .social-icons a:active {
    color: #667085;
}

@media (max-width: 767px) {
    footer #footer-bottom {
        padding-bottom: 1.5em;
        padding-top: 0;
        border: none;
    }

    footer #footer-bottom .container {
        border-top: 1px solid #eaecf0;
        padding-top: 1.5em;
    }
}

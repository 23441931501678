.single-blog {
    padding: 3em 10em;
}

.single-blog .details .author-img {
    width: 50px;
    border-radius: 50px;
    border: 1px solid #f1f0f0;
}

.single-blog .details h4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #292929;
}

.single-blog .details p {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #757575;
}

.single-blog h1 {
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #0a0a33;
    margin-top: 35px;
    margin-bottom: 20px;
}

.single-blog #main-content img {
    width: 100%;
    /* height: 700px; */
    object-fit: cover;
    object-position: center;
    margin: 32px 0;
}

.single-blog #main-content ul,
.single-blog #main-content ol {
    padding-left: 1.3rem;
    margin-bottom: 32px;
}

.single-blog #main-content p,
.single-blog #main-content li,
.single-blog #main-content li::marker {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #444549;
    margin-bottom: 32px;
}

.single-blog #main-content h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #141414;
}

.single-blog #main-content blockquote {
    padding-left: 23px;
    margin: 2em 0;
    border-left: 3px solid rgb(41 41 41);
    margin-left: -20px;
    font-family: 'Poppins', sans-serif;
    font-style: italic;
    font-size: 20px;
    line-height: 32px;
}

.single-blog #main-content li::marker {
    font-weight: 700;
}

.single-blog #main-content figcaption,
.single-blog #main-content figcaption strong {
    text-align: center;
    margin-top: 12px;
    margin-bottom: 32px;
    font-weight: 700;
}

.single-blog #main-content img:has(+ figcaption) {
    margin-bottom: 0;
}

.single-blog #main-content p a,
.single-blog #main-content a {
    color: #292929;
}
.single-blog #main-content p a:hover,
.single-blog #main-content a:hover,
.single-blog #main-content p a:focus,
.single-blog #main-content a:focus,
.single-blog #main-content p a:active,
.single-blog #main-content a:active {
    color: #444549;
}

.single-blog #main-content .hidden-img {
    display: none;
}

.single-blog .insight h3,
.single-blog .share h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #141414;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.single-blog .insight img {
    width: 300px;
    height: 200px;
    object-fit: cover;
    object-position: center;
}

.single-blog .insight h5 {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #141414;
}

.single-blog .insight p {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.single-blog .single-blog-info {
    padding-top: 5em;
}

@media (max-width: 1199px) {
    .single-blog .insight img {
        width: 200px;
        height: 150px;
    }
}

@media (max-width: 1099px) {
    .single-blog {
        padding: 3em 8em;
    }
}

@media (max-width: 991px) {
    .single-blog {
        padding: 3em 1.5rem;
    }
}

@media (max-width: 767px) {
    .single-blog .insight {
        margin-top: 5em;
    }
    .single-blog .insight img {
        width: 100%;
        height: 100%;
        margin-bottom: 2rem;
    }

    .single-blog .single-blog-info {
        padding-top: 4em;
    }

    .single-blog .single-blog-info .share {
        padding-top: 46px;
        padding-bottom: 36px;
        padding-left: 30px;
        padding-right: 30px;
        border-top: 1px solid #d2d1d4;
        border-bottom: 1px solid #d2d1d4;
    }

    .single-blog .share h3 {
        display: inline;
        margin-bottom: 0;
    }

    .single-blog .insight h3 {
        margin-bottom: 31px;
    }

    .single-blog .insight .badge {
        margin-bottom: 24px;
        padding: 2px 10px;
        background-color: hsla(0, 0%, 64%, 0.2);
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #606060;
        text-transform: capitalize;
    }

    .single-blog .insight .desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #444549;
    }
}

@media (max-width: 575px) {
    .single-blog {
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }
    .single-blog .info-time {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #717d8a;
    }

    .single-blog h1 {
        margin-top: 12px;
        margin-bottom: 20px;
    }

    .single-blog .details {
        margin-top: 20px;
        margin-bottom: 32px;
    }

    .single-blog .details h4 {
        font-weight: 500;
    }
}

@media (max-width: 425px) {
    .single-blog h1 {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.02em;
        color: #292929;
    }

    .single-blog #main-content p,
    .single-blog #main-content li,
    .single-blog #main-content li::marker {
        font-weight: 400;
        font-size: 17px;
        line-height: 28px;

        color: #373f47;
    }

    .single-blog #main-content h3 {
        font-size: 21px;
    }

    .single-blog #main-content blockquote {
        font-size: 17px;
        line-height: 28px;
        border: none;
    }

    .single-blog .single-blog-info .share {
        padding-left: 40px;
        padding-right: 40px;
    }

    .single-blog .share h3 {
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
        color: #292929;
    }

    .single-blog .insight h3 {
        font-family: 'Raleway';
        font-weight: 600;
        font-size: 18px;
        line-height: 41px;
        color: #292929;
        text-transform: none;
    }

    .single-blog .insight .info {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #717d8a;
    }
}

@media (max-width: 375px) {
    .single-blog .single-blog-info .share {
        padding-left: 25px;
        padding-right: 25px;
    }
}

@media (max-width: 320px) {
    .single-blog .single-blog-info .share {
        padding-left: 20px;
        padding-right: 20px;
    }
}
